<template>
  <div class="table-responsive mh-230 table-scrollbar">
    <table class="table table-bordered table-hover table-striped ">
      <thead>
        <tr>
          <th>Questions</th>
          <th>Answers</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="qa in queAnsData"
          :key="qa.id"
        >
          <td>{{ qa.question }}</td>
          <td>
            <span v-if="qa.from_master && catPro(qa.master_table)">
              <span
                v-for="(ans, i) in qa.answer_text"
                :key="i"
              >
                <p class="mb-0"><b>Category: </b>{{ ans.category_name ? ans.category_name : '' }}</p>
                <p class="mb-0"><b>Product: </b>{{ ans.products.product_name ? ans.products.product_name : '' }}</p>
                <p class="mb-0"><b>Sub-Product: </b>{{ ans.products.sub_product_name[0] ? ans.products.sub_product_name[0] : '' }}</p>
                <p class="mb-0"><b>Quality: </b>{{ ans.products.quality.quality_name ? ans.products.quality.quality_name : '' }}</p>
              </span>
            </span>
            <span v-else-if="qa.q_type !== constants.TEMPLATE_GROUP_FILE_TEXT && qa.answer_text.length">
              {{ qa.answer_text }}
            </span>
            <p
              v-if="qa.other_text"
              class="mb-0"
            > <b>Other Text:</b>  {{ qa.other_text }}
            </p>
            <span
              v-for="(media, i) in qa.answer_media"
              :key="i"
              class="mr-1"
            >
            <span v-if="media">
              <a target="_blank" :href="media">
                <b-img
                  :src="require('@/assets/images/icons/doc.png')"
                  height="auto"
                  width="20"
                />
              </a>
            </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <b-row
      v-for="qa in queAnsData"
      :key="qa.id"
      class="my-1"
    >
      <b-col
        cols="12"
        class="font-weight-bolder"
      >
        {{ `Q. ` }} {{ qa.question }}
      </b-col>
      <b-col
        cols="12"
        class="d-flex"
      >
        <span class="mr-1">Ans. </span>
        <span v-if="qa.from_master">
          <span
            v-for="(ans, i) in qa.answer_text"
            :key="i"
          >
            <p class="mb-0">{{ `Category: ${ans.category_name}` }}</p>
            <p class="mb-0">{{ `Product: ${ans.products.product_name}` }}</p>
            <p class="mb-0">{{ `Sub-Product: ${ans.products.sub_product_name[0]}` }}</p>
            <p class="mb-0">{{ `Quality: ${ans.products.quality.quality_name}` }}</p>
          </span>
        </span>
        <span v-else-if="qa.q_type !== constants.TEMPLATE_GROUP_FILE_TEXT && qa.answer_text.length">
          {{ qa.answer_text }}
        </span>
        <span
          v-if="qa.other_text"
          class="ml-2"
        > <b>Other Ans. Text:</b>  {{ qa.other_text }}
        </span>
      </b-col>
      <b-col
        cols="12"
        class="d-flex"
      >
        <span
          v-for="(media, i) in qa.answer_media"
          :key="i"
          class="mx-1"
        ><span v-if="media">
          <a :href="Object.values(media)[0]">{{ Object.keys(media)[0] }}.{{ getFileExtension(Object.values(media)[0]) }}
          </a>
        </span>
        </span>
      </b-col>
    </b-row> -->
    <div>
      <div
        v-for="(parent) in repeaterArray"
        :key="parent.id"
        :class="parent.is_inner_repeater_questions === 0 ? 'table-responsive mh-230 table-scrollbar' : ''"
      >
        <div v-if="parent.is_inner_repeater_questions === 0">
          <table
            class="table table-bordered table-hover table-striped"
          >
            <thead>
              <tr>
                <th :colspan="parent.repeater[0].repeater_data.length + 1">
                  {{ parent.question }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(item) in parent.repeater[0].repeater_data"
                  :key="item.id"
                >{{ item.question }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(el) in parent.repeater"
                :key="el.id"
              >
                <td
                  v-for="(item) in el.repeater_data"
                  :key="item.id"
                >
                  {{ item.answer_text ? item.answer_text : getAnswerText(item.answer_id, item.master_data ? item.master_data :  item.options) }}
                <div
                  v-show="item.answer_media"
                  class="text-center"
                >
                  <span
                    v-for="(media, i) in item.answer_media"
                    :key="i"
                    class="mr-1"
                  >
                  <span v-if="media">
                    <a target="_blank" :href="media">
                      <b-img
                        :src="require('@/assets/images/icons/doc.png')"
                        height="auto"
                        width="20"
                      />
                    </a>
                  </span>
                  </span>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

          <!-- inner_repeater_questions -->
      <div
        v-for="(parent) in repeaterArray"
        :key="parent.id"
        :class="parent.is_inner_repeater_questions === 1 ? 'outer-border my-2' : ''"
      >
        <div v-if="parent.is_inner_repeater_questions === 1">
          <div
            v-for="(el) in parent.repeater"
            :key="el.id"
            class="border-bottom"
          >
            <b-row class="p-1">
              <b-col
                v-for="(item) in el.repeater_data.slice(0, -1)"
                :key="item.id"
              >
                <span class="font-weight-bold">{{ item.question }}</span> : {{ item.answer_text ? item.answer_text : getAnswerText(item.answer_id, item.master_data ? item.master_data :  item.options) }}
              </b-col>
            </b-row>
            <div
              v-for="(item) in el.repeater_data"
              :key="item.id"
            >
            <div v-if="item.group_id === 4007">
                <div class="table-responsive mh-230 table-scrollbar">
                  <table class="table table-bordered table-hover table-striped mt-1">
                    <thead class="fixed-header">
                      <tr>
                        <th
                          v-for="(ele) in item.inner_repeater[0].repeater_data"
                          :key="ele.id"
                        >
                          {{ ele.question }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value) in item.inner_repeater"
                        :key="value.id"
                      >
                        <td
                          v-for="(inner) in value.repeater_data"
                          :key="inner.id"
                        >
                          {{ inner.answer_text ? inner.answer_text : getAnswerText(inner.answer_id, inner.master_data ? inner.master_data :  inner.options) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { catProId, getFileExtension } from '@/utils/common'
import constants from '@/constants'

export default {
  name: 'ViewInformation',
  components: {
  },
  props: {
    queAnsData: { type: Array, required: false, default: () => [] },
    repeaterArray: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      constants,
    }
  },
  mounted() {
  },
  methods: {
    getAnswerText(answerId, Options) {
      const answer = Options.find(item => item.id === answerId)
      return answer ? answer.options : ''
    },
    catPro(id) {
      return catProId.includes(id)
    },
    getFileExtension(file) {
      return getFileExtension(file)
    },
  },
}
</script>

<style>
table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}
.mh-230{
    max-height: 275px;
}
.table-scrollbar::-webkit-scrollbar-track {
  background: rgb(211, 210, 210);
}
.table-scrollbar::-webkit-scrollbar-thumb{
    /* display: none; */
    background-color: #7367f0;
    border-radius: 6px;
}
.table-scrollbar::-webkit-scrollbar{
    /* display: none; */
    /* scrollbar-color: red; */
    width: 10px;
}
.outer-border {
  border: 1px solid #ebe9f1;
  border-radius: 4px;
}
</style>
