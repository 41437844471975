<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <!-- :class="rules" -->
    <validation-provider
      #default="{ errors }"
      :name="'Question'"
      :vid="id"
      :rules="rules ? 'required' : ''"
    >
      <!-- :rules="rules" -->
      <!-- <b-input-group class="input-group-merge">
        <b-input-group-prepend
          v-if="icon"
          is-text
        >
          <feather-icon :icon="icon" />
        </b-input-group-prepend> -->

      <b-form-textarea
        v-if="type == 'textarea' || type == 'Textarea'"
        :id="id"
        v-model="form.qna[id].answer_id"
        :placeholder="placeholder"
        :rows="rows"
        :name="inputname"
        @input="$root.$emit('getform', $event, inputname, type)"
      />

      <div
        v-else-if="type == 'checkbox' || type == 'Checkbox'"
        class="row mx-1 mt-1 w-100"
      >
        <b-col class="col-10 pb-1 pl-0">
          <b-form-checkbox
          :checked="selectAll" @change="toggleAllCheckboxes(id)"
          >Select All
        </b-form-checkbox>
        </b-col>
        <b-form-checkbox
          v-for="(crv, index) in crvalue"
          :key="index"
          v-model="form.qna[id].answer_id"
          class="col-5 mb-1"
          :name="inputname"
          :value="crv.id"
          :switch="radioswitch"
          :inline="'inline'"
          @change="$root.$emit('getform', crvalue[index], id, $event.includes(crv.id))"
        >
          <span class="px-1"> {{ crv.options }} </span>
        </b-form-checkbox>
      </div>

      <div
        v-else-if="type == 'radio' || type == 'radio'"
        class="d-flex"
      >
        <!-- w-100 justify-content-between -->
        <b-form-radio
          v-for="(crv, index) in crvalue"
          :key="index"
          v-model="form.qna[id].answer_id"
          :name="inputname"
          :value="crv.id"
          @change="$root.$emit('getform', crvalue[index], id, true)"
        >
          <span class="pl-1 pr-3">{{ crv.options }}</span>
        </b-form-radio>
      </div>

      <vue-autosuggest
        v-else-if="type == 'autosuggest' || type == 'Autosuggest'"
        ref="autocomplete"
        v-model="form.qna[id].answer_id"
        :suggestions="suggestions"
        :input-props="{
          id: id,
          placeholder: placeholder,
          class: 'form-control',
          name: inputname,
        }"
        :section-configs="sectionConfigs"
        :limit="10"
        :render-suggestion="renderSuggestion"
        :on-selected="onSelected"
        @input="fetchResults"
      />

      <v-select
        v-else-if="(type == 'select' || type == 'Select')"
        v-model="form.qna[id].answer_id"
        :name="inputname"
        class="form-control p-0 border-0"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :placeholder="'Select '+label"
        :multiple="multiple"
        label="options"
        :reduce="op => op.id"
        :options="options"
        @option:selected="$root.$emit('getform', $event, id)"
      />

      <!-- <multiselect
        v-else-if="(type == 'select' || type == 'Select') && (multiple)"
        v-model="form.qna[id].answer_id"
        :name="inputname"
        class="form-control p-0 border-0"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :multiple="multiple"
        :placeholder="'Select '+label"
        label="options"
        :reduce="op => op.id"
        :options="options"
        @option:selected="$root.$emit('getform', $event, id)"
      >
        <template
          slot="beforeList"
        >
          <b-form-checkbox
            v-model="selectAll"
            class="col-md-6 m-1"
            @input="selectAll ? form.qna[id].answer_id = options : form.qna[id].answer_id = []"
          >
            Select All
          </b-form-checkbox>
        </template>
        <template
          slot="tag"
          slot-scope="{ option, remove }"
        >
          <span class="custom__tag mul-sel-tag"><span>{{ option.options }}</span><span
            class="custom__remove cursor-pointer"
            @click="remove(option)"
          >❌</span></span>
        </template>
      </multiselect> -->

      <flat-pickr
        v-else-if="type == 'date-picker' || type == 'Datetime'"
        v-model="form.qna[id].answer_text"
        class="form-control"
        :config="{ dateFormat: dateformat,}"
        @input="$root.$emit('getform', $event, inputname, type)"
      />

      <b-row
        v-else-if="type == 'file' || type == 'File'"
      >
        <b-col
          cols="6"
        >
          <b-form-file
            :id="id"
            v-model="form.qna[id].answer_media"
            vid="Question"
            :name="inputname"
            type="file"
            :placeholder="`Upload File`"
          />
        </b-col>
        <b-col
          cols="6"
        >
          <span
          v-if="form.qna[id].answer_media[0]"
          >
            <a target="_blank" :href="form.qna[id].answer_media[0]">
              <b-img
                :src="require('@/assets/images/icons/doc.png')"
                height="auto"
                width="30"
              />
            </a>
          </span>
        </b-col>
      </b-row>

      <b-form-input
        v-else-if="type == 'text' || type == 'Text'"
        :id="id"
        v-model="form.qna[id].answer_text"
        :type="type"
        :placeholder="placeholder"
        :name="inputname"
        @input="$root.$emit('getform', $event, inputname, type)"
      />
      <!-- </b-input-group> -->
      <small class="text-danger">{{ errors[0] }}</small>

      <div class="my-1">
        <b-form-input
          v-if="isOtherText[id] || form.qna[id].other_text"
          :id="id"
          v-model="form.qna[id].other_text"
          type="text"
          placeholder="Other Option"
          :name="inputname"
          @input="$root.$emit('getform', $event, inputname, type)"
        />
      </div>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import vSelect from 'vue-select'
// import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { VueAutosuggest } from 'vue-autosuggest'
import axios from 'axios'

export default {
  components: {
    flatPickr,
    ValidationProvider,
    vSelect,
    // Multiselect,
    VueAutosuggest,
  },
  props: {
    media: { type: Array, required: false },
    isOtherText: { type: Array, required: false },
    form: { type: Object, required: true },
    id: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    type: { type: String, required: true },
    rules: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: '' },
    inputname: { type: String, required: true },
    value: { type: String, required: false, default: '' },
    // Checkbox / Radio Value and Label
    crvalue: { type: Array, required: false, default: () => [] },
    crlabel: { type: String, required: false, default: '' },

    placeholder: { type: String, required: false, default: '' },
    // Rows for TextArea
    rows: { type: Number, required: false, default: 5 },
    // Vue Select with Multiple Selector
    multiple: { type: Boolean, required: false, default: false },
    // Vue Select with Options
    options: { type: Array, required: false, default: () => [] },
    // Switch Checkbox Params
    radioswitch: { type: Boolean, required: false, default: false },
    inline: { type: Boolean, required: false, default: false },
    // AutoSuggest Params
    suggestapi: { type: String, required: false, default: '' },
    // DatePicker
    enabletime: { type: Boolean, required: false, default: true },
    nocalendar: { type: Boolean, required: false, default: true },
    dateformat: { type: String, required: false, default: 'Y-m-d' },
    mode: { type: String, required: false, default: 'range' },

  },
  data() {
    return {
      selectAll: false,
      required,
      email,
      suggestions: [],
      file: [],
      val: this.value,
      crva: [],
      crval: []/* this.crvalue */,
      sectionConfigs: {
        selection: {
          onSelected: selected => {
            this.$root.$emit('getform', selected.item.name, this.inputname, this.type)
          },
        },
      },
    }
  },
  mounted() {
    // crva() {
    // const valle = []
    const a = this
    this.crvalue.forEach((e, i) => {
      if (e.checked) { this.crva[i] = e.title; a.$root.$emit('getform', e.title, this.inputname, this.type) } else { this.crva[i] = '' }
    })

    // return valle
    // },
  },
  methods: {
    toggleAllCheckboxes(id) {
      this.selectAll = !this.selectAll
      if (this.selectAll) {
        this.form.qna[id].answer_id = this.crvalue.map(item => item.id)
      } else {
        this.form.qna[id].answer_id = []
      }
    },
    onSelected(option) {
      return option
    },
    fetchResults() {
      const { val } = this
      const usersPromise = axios.get(this.suggestapi)

      Promise.all([usersPromise]).then(values => {
        this.suggestions = []
        this.selected = null
        const users = this.filterResults(values[0].data, val, 'name')
        this.suggestions.push({ name: 'selection', data: users })
      })
    },
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
