<template>
  <div
    v-if="Object.entries(template).length && !viewInformation"
    class="tab-card-fixed-height"
  >
    <b-form
      id="dynamicForm"
      enctype="multipart/form-data"
      autocomplete="off"
      @submit.prevent="dynamicFormSubmit"
    >
      <b-col
        v-if="alreadySubmitted"
        xl="12"
        class="text-right"
      >
        <feather-icon
          icon="XCircleIcon"
          class="text-danger font-medium-3 cursor-pointer"
          @click="viewInformation = true"
        />
      </b-col>
      <validation-observer
        ref="dynamicForm"
      >
        <div
          v-for="(temp, i) in template"
          :key="temp.id"
        >
          <div
            class="question font-weight-bold"
            :class="temp.is_required ? 'dynamic_required' : ''"
          >
            <!--  {{ `Q${i}.` }}  -->{{ temp.question }}
          </div>
          <!-- v-if="temp.from_master && catPro(temp.master_id)" -->
          <div
            v-if="temp.from_master && catPro(temp.master_table)"
            class="my-1"
          >
            <b-row>
              <b-col cols="6">
                <label for="Category">Category</label>
                <v-select
                  id="Category"
                  v-model="form.qna[i].answer_text.category.id"
                  name="inputname"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select Category"
                  label="category_name"
                  :multiple="temp.is_multiple ? true : false"
                  :reduce="md => md.id"
                  :options="temp.master_data"
                  @input="onChangeCategory($event,i)"
                />

              </b-col>
              <b-col
                v-if="form.qna[i].answer_text.category.id"
                cols="6"
              >
                <label
                  v-if="productsOptions[i].length"
                  for="Product"
                >Product</label>
                <v-select
                  v-if="productsOptions[i].length"
                  id="Product"
                  v-model="form.qna[i].answer_text.category.products.id"
                  name="inputname"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select Product"
                  label="name"
                  :multiple="temp.is_multiple ? true : false"

                  :reduce="md => md.id"
                  :options="productsOptions[i]"
                  @input="onChangeProduct(i, productsOptions[i].filter(a => a.id == form.qna[i].answer_text.category.products.id ), 0, form.qna[i].answer_text.category.products.id)"
                />

              </b-col>
            </b-row>
            <b-row
              v-if="form.qna[i].answer_text.category.products.id"
            >
              <b-col
                v-for="(val, ind) in subCatCount"
                :key="ind"
                cols="6"
              >
                <label
                  v-if="subProductsOptions[i].length && subProductsOptions[i][ind].length"
                  for="Sub-Catgory"
                >Sub-Catgory</label>
                <v-select
                  v-if="subProductsOptions[i].length && subProductsOptions[i][ind].length"
                  id="Sub-Catgory"
                  v-model="form.qna[i].answer_text.category.products.subproducts[ind]"
                  name="inputname"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select Sub-Category"
                  label="name"
                  :multiple="temp.is_multiple ? true : false"

                  :reduce="md => md.id"
                  :options="subProductsOptions[i][ind]"
                  @input="onChangeProduct(i, subProductsOptions[i][ind].filter((a,i) => i == form.qna[i].answer_text.category.products.subproducts[ind] ), ind+1)"
                />

              </b-col>
            </b-row>
            <b-row v-if="form.qna[i].answer_text.category.products.id || form.qna[i].answer_text.category.products.quality.id">
              <b-col
                v-if="subProductsQualityOptions[i] && subProductsQualityOptions[i].length"
                cols="6"
              >
                <label for="Quality">Quality</label>
                <v-select
                  id="Quality"
                  v-model="form.qna[i].answer_text.category.products.quality.id"
                  name="inputname"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select Quality"
                  label="quality_name"
                  :multiple="temp.is_multiple ? true : false"

                  :reduce="md => md.id"
                  :options="subProductsQualityOptions[i]"
                />

              </b-col>
            </b-row>
          </div>
          <div
            v-else-if="temp.from_master"
          >
            <CustomInput
              :id="i.toString()"
              :form="form"
              :inputname="temp.id.toString()"
              :type="temp.group"
              :crvalue="temp.master_data"
              :options="temp.master_data"
              :multiple="temp.is_multiple ? true : false"
              :rules="temp.is_required ? true : false"
              :is-other-text="isOtherText"
            />
          </div>
          <div
            v-else
            class="my-1"
          >
            <CustomInput
              :id="i.toString()"
              :form="form"
              :inputname="temp.id.toString()"
              :type="temp.group"
              :crvalue="temp.option_data"
              :options="temp.option_data"
              :multiple="temp.is_multiple ? true : false"
              :rules="temp.is_required ? true : false"
              :is-other-text="isOtherText"
              :media="media"
            />
          </div>
        </div>
        <CustomInput2
          :repeaterArray="repeaterArray"
          :ducumentFile="ducumentFile"
        />
        <b-col
          v-if="isSubmitBtn"
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="danger"
            class="mr-1"
            @click="queAnsData.length ? viewInformation = true : ''"
          >
            Cancel
          </b-button>
        </b-col>
      </validation-observer>
    </b-form>
  </div>
  <div
    v-else-if="viewInformation"
    class="row"
  >
    <b-col
      xl="12"
      class=""
    >
      <div class="d-flex justify-content-end mb-5px">
        <!-- <b-button
          variant="outline-info"
          class="py-0 mx-1"
        > -->
        <!--  -->
        <feather-icon
          v-if="is_edit"
          icon="EditIcon"
          class="text-info cursor-pointer font-medium-2"
          @click="editInformation"
        />
        <!-- <span class="text-info"> Edit</span> -->
        <!-- </b-button> -->
        <!-- <b-button
          variant="outline-info"
        > -->
        <span @click="viewPDFInformation(template_id)">
          <i
            class="far fa-file-pdf cursor-pointer text-info ml-1  font-medium-2"
          />
        </span>
        <!-- <span class="text-info"> PDF</span> -->
        <!-- </b-button> -->
      </div>
      <ViewInformation
        :is_edit="is_edit"
        :que-ans-data="queAnsData"
        :repeaterArray="repeaterArray"
      />
    </b-col>
    <div v-if="template_id">
      <ViewPDFModal
        :service_id="service_id"
        :opportunity_id="opportunity_id"
        :template_id="template_id"
      />
    </div>
  </div>

  <div
    v-else
    class="tab-card-fixed-height"
  >
    <b-col
      xl="12"
      class="padding-b-28 h-100"
    >
      <b-card class="h-100">
        <b-row class="h-100 align-items-center">
          <b-col
            cols="12"
            class="pr-0 text-center"
          >
            {{ 'No Form Found' }}

            <b-col cols="12">
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="danger"
                class="mt-3"
                @click="queAnsData.length ? viewInformation = true : ''"
              >
                Back
              </b-button> -->
            </b-col>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </div>
</template>

<script>
// import $ from 'jquery'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import constants from '@/constants'
import CustomInput from './CustomInputForm/CustomInput.vue'
import ViewInformation from './ViewInformation.vue'
import ViewPDFModal from './ViewPDFModal.vue'
import {
  catProId,
  failedToast, hideLoader, showLoader, successToast,
} from '@/utils/common'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import CustomInput2 from './CustomInputForm/CustomInput2.vue'

export default {
  name: 'ViewTemplate',
  components: {
    CustomInput,
    vSelect,
    ViewInformation,
    FeatherIcon,
    ValidationObserver,
    ViewPDFModal,
    CustomInput2,
  },
  directives: {
    Ripple,
  },
  props: ['template_ids'],
  data() {
    return {
      template: [],
      media: [],
      subCatCount: 0,
      queAnsData: [],
      subProductsOptions: [],
      alreadySubmitted: false,
      subProductsQualityOptions: [],
      isOtherText: [],
      viewInformation: false,
      productsOptions: {},
      form: {
        qna: [],
      },
      repeaterArray: [],
      ducumentFile: [],
      template_id: '',
      isSubmitBtn: false,
      service_id: '',
      is_edit: false,
      opportunity_id: '',
      is_proposal_tab: '',
    }
  },
  mounted() {
    if (this.template_ids) {
      this.getTemplate()
    }
    this.getRepeaterQuestionList()
    this.$root.$on('getform', (val, i, isChecked) => {
      if (val.is_other_text && isChecked) {
        this.isOtherText[i] = 1
      } else if (val.is_other_text && !isChecked) {
        this.isOtherText[i] = 0
      }
    })
    this.$root.$on('loadInfoReceiveD', opportunityData => {
      this.template = []
      this.media = []
      this.subCatCount = 0
      this.queAnsData = []
      this.subProductsOptions = []
      this.alreadySubmitted = false
      this.subProductsQualityOptions = []
      this.isOtherText = []
      this.viewInformation = false
      this.productsOptions = {}
      this.form = {}
      this.service_id = opportunityData.enc_service_id
      this.opportunity_id = opportunityData.lead_opportunity_id
      this.template_id = opportunityData.template_id
      this.isSubmitBtn = true
      this.is_edit = true
    })
    this.$root.$on('loadProposalD', opportunityData => {
      this.template = []
      this.media = []
      this.subCatCount = 0
      this.queAnsData = []
      this.subProductsOptions = []
      this.alreadySubmitted = false
      this.subProductsQualityOptions = []
      this.isOtherText = []
      this.viewInformation = false
      this.productsOptions = {}
      this.form = {}
      this.service_id = opportunityData.enc_service_id
      this.opportunity_id = opportunityData.lead_opportunity_id
      this.template_id = opportunityData.enc_proposal_id
      this.is_proposal_tab = opportunityData.is_proposal_tab
      this.isSubmitBtn = true
      this.is_edit = true
    })
  },
  beforeDestroy() {
    this.$root.$off('LoadInfoReceive')
    this.$root.$off('loadProposal')
    this.template = []
    this.media = []
    this.subCatCount = 0
    this.queAnsData = []
    this.subProductsOptions = []
    this.alreadySubmitted = false
    this.subProductsQualityOptions = []
    this.isOtherText = []
    this.viewInformation = false
    this.productsOptions = {}
    this.form = {}
  },
  methods: {
    loadInfoReceiveData() {
      this.getTemplate()
    },
    loadProposalData() {
      this.getTemplate()
    },
    async getRepeaterQuestionList() {
      this.repeaterArray = []
      showLoader()
      await store.dispatch('questionsStore/getRepeaterQuestionAnswers',
        {
          template_id: this.template_id ? this.template_id : this.template_ids,
          service_id: this.service_id,
          opportunity_id: this.opportunity_id,
          is_proposal_tab: this.is_proposal_tab,
        }).then(response => {
        this.repeaterArray = response.data.data
        hideLoader()
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
    },
    catPro(id) {
      return catProId.includes(id)
    },
    viewPDFInformation(id) {
      this.$bvModal.show(id)
    },
    editInformation() {
      this.viewInformation = false
    },
    onChangeCategory(val, i) {
      this.productsOptions[i] = []
      this.form.qna[i].products = ''
      this.form.qna[i].answer_text.category.products.subproducts = []
      this.form.qna[i].answer_text.category.products.quality.id = ''
      this.form.qna[i].answer_text.category.products.id = ''
      if (Array.isArray(val)) {
        val.forEach(v => {
          // eslint-disable-next-line eqeqeq
          if ('Product' in this.template[i].master_data.filter(ele => ele.id == v)[0]) {
            // eslint-disable-next-line eqeqeq
            const products = this.template[i].master_data.filter(ele => ele.id == v)[0].Product
            this.productsOptions[i].push(...products)
          }
        })
      } else {
      // eslint-disable-next-line eqeqeq
        this.productsOptions[i] = this.template[i].master_data.filter(ele => ele.id == val)[0].Product
      }
    },
    onChangeProduct(i, subCats, ind, val) {
      if (!ind) {
        this.form.qna[i].answer_text.category.products.subproducts = []
        this.form.qna[i].answer_text.category.products.quality.id = ''
      }
      // eslint-disable-next-line no-unused-vars
      let subCat = []
      if (subCats.length) {
        subCat = subCats['0']
      }

      this.subCatCount = 1
      // eslint-disable-next-line eqeqeq
      if (ind) {
        this.subCatCount += ind
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.subCatCount; index++) {
        // eslint-disable-next-line eqeqeq
        if (i in this.subProductsOptions == false) {
          this.subProductsOptions[i] = []
        // eslint-disable-next-line eqeqeq
        } else if (index in this.subProductsOptions[i] == false) {
          this.subProductsOptions[i][index] = []
        }

        if (Array.isArray(val)) {
          this.subProductsOptions[i] = []
          this.subProductsOptions[i][ind] = []
          val.forEach(v => {
            // eslint-disable-next-line eqeqeq
            if ('childs' in this.productsOptions[i].filter(ele => ele.id == v)[0]) {
            // eslint-disable-next-line eqeqeq
              const { childs } = this.productsOptions[i].filter(ele => ele.id == v)[0]
              this.subProductsOptions[i][ind].push(...childs)
            }
          })
        } else if ('childs' in subCat) {
          // eslint-disable-next-line prefer-destructuring
          this.subProductsOptions[i][ind] = subCat.childs
        }
        // eslint-disable-next-line eqeqeq
        if (i in this.subProductsOptions == false) {
          this.subProductsQualityOptions[i] = []
        }
        // if ('quality' in subCat) {
        //   // eslint-disable-next-line prefer-destructuring
        //   this.subProductsQualityOptions[i] = subCat.quality
        // }
        if (Array.isArray(val)) {
          this.subProductsQualityOptions[i] = []
          val.forEach(v => {
            // eslint-disable-next-line eqeqeq
            if ('quality' in this.productsOptions[i].filter(ele => ele.id == v)[0]) {
            // eslint-disable-next-line eqeqeq
              const { quality } = this.productsOptions[i].filter(ele => ele.id == v)[0]
              this.subProductsQualityOptions[i].push(...quality)
            }
          })

          this.subProductsQualityOptions[i] = this.subProductsQualityOptions[i].filter((obj, pos, arr) => arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos)
        } else if ('quality' in subCat) {
          // eslint-disable-next-line prefer-destructuring
          this.subProductsQualityOptions[i] = subCat.quality
        }
      }
    },
    onChangeSubProduct(i, subCats, ind) {
      this.subCatCount = 1
      // eslint-disable-next-line eqeqeq
      if (ind) {
        this.subCatCount += ind
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.subCatCount; index++) {
        // eslint-disable-next-line eqeqeq
        if (i in this.subProductsOptions == false) {
          this.subProductsOptions[i] = []
        // eslint-disable-next-line eqeqeq
        } else if (index in this.subProductsOptions[i] == false) {
          this.subProductsOptions[i][index] = []
        }
        if (subCats[0].childs) {
          // eslint-disable-next-line prefer-destructuring
          this.subProductsOptions[i][index] = subCats[0].childs
        }
        // eslint-disable-next-line eqeqeq
        if (i in this.subProductsOptions == false) {
          this.subProductsQualityOptions[i] = []
        }
        if (subCats[0].quality) {
          // eslint-disable-next-line prefer-destructuring
          this.subProductsQualityOptions[i] = subCats[0].quality
        }
      }
    },
    dynamicFormSubmit() {
      this.$refs.dynamicForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          Object.entries(this.form).forEach(([key, value]) => {
            if (key !== 'qna') {
              formData.append(key, value)
            }
          })
          this.form.qna.forEach((qa, index) => {
            if (qa.q_type === constants.TEMPLATE_GROUP_FILE_TEXT) {
              // this.form.qna[index].file_count = qa.answer_id.length
              const document = []
              document.push(this.form.qna[index].answer_media)
              if (document) {
                document.forEach(a => {
                  formData.append(`file_${qa.question_id}`, a)
                })
              }
            }
          })
          formData.append('qna', JSON.stringify(this.form.qna))
          formData.append('repeater_question', JSON.stringify(this.repeaterArray))
          formData.append('template_id', this.template_id)
          formData.append('service_id', this.service_id)
          formData.append('opportunity_id', this.opportunity_id)
          if (this.ducumentFile) {
            this.ducumentFile.forEach(a => {
              formData.append(`file_${a.question_id}_${a.id}`, a.answer_media)
            })
          }
          this.submitDynamicForm(formData)
        }
      })
    },
    async submitDynamicForm(formData) {
      showLoader()
      await store.dispatch('questionsStore/saveQuestionAnswers', formData).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          this.getSurvey()
          hideLoader()
          this.viewInformation = true
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
      this.ducumentFile = []
    },

    async getSurvey() {
      this.getRepeaterQuestionList()
      showLoader()
      await store.dispatch('questionsStore/getQuestionAnswers',
        {
          template_id: this.template_id ? this.template_id : this.template_ids,
          service_id: this.service_id,
          opportunity_id: this.opportunity_id,
          is_proposal_tab: this.is_proposal_tab,
        }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.queAnsData = response.data.data
          if (this.queAnsData.length) {
            this.form.qna = this.queAnsData
            this.viewInformation = true
            this.alreadySubmitted = true
            this.template.forEach((t, i) => {
              // eslint-disable-next-line eqeqeq
              if (t.group_id == constants.TEMPLATE_GROUP_FILE) {
                this.media[t.id] = this.form.qna[i].answer_media
              }
              if (t.from_master) {
                t.master_data.forEach((prods, pi) => {
                  // eslint-disable-next-line eqeqeq
                  if (prods.id == this.form.qna[i].answer_text.category.id) {
                    this.productsOptions[i] = t.master_data[pi].Product
                    this.productsOptions[i].forEach(proSel => {
                      // eslint-disable-next-line eqeqeq
                      if (proSel.id == this.form.qna[i].answer_text.category.products.id) {
                        this.subProductsQualityOptions[i] = proSel.quality
                        this.subCatCount = this.form.qna[i].answer_text.category.products.subproducts.length
                        this.form.qna[i].answer_text.category.products.subproducts.forEach((subid, subi) => {
                          if ('childs' in proSel) {
                            if (this.subProductsOptions[i]) {
                              this.subProductsOptions[i][subi] = proSel.childs
                            } else {
                              this.subProductsOptions[i] = []
                              this.subProductsOptions[i][subi] = proSel.childs
                            }
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          }
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
      hideLoader()
    },
    async getTemplate() {
      showLoader()
      await store.dispatch('questionsStore/getTemplateJSON',
        {
          template_id: this.template_id ? this.template_id : this.template_ids,
          service_id: this.service_id,
          opportunity_id: this.opportunity_id,
          is_proposal_tab: this.is_proposal_tab,
        }).then(response => {
        this.form.qna = []
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          response.data.data.question_data.forEach(temp => {
            this.isOtherText[temp.id] = 0
            // eslint-disable-next-line eqeqeq
            if (!catProId.includes(temp.master_table)) {
              this.form.qna.push({
                question_id: temp.id, q_type: temp.group, answer_id: [], answer_media: [], file_count: 0, answer_text: '', from_master: temp.from_master, other_text: '',
              })
            // eslint-disable-next-line eqeqeq
            } else if (temp.group_id == constants.TEMPLATE_GROUP_CHECKBOX || temp.group_id == constants.TEMPLATE_GROUP_FILE) {
              this.form.qna.push({
                question_id: temp.id, q_type: temp.group, answer_id: [], answer_media: [], file_count: 0, answer_text: { category: { id: '', products: { id: '', subproducts: [], quality: { id: '' } } } }, from_master: temp.from_master, other_text: '',
              })
            // eslint-disable-next-line eqeqeq
            } else if (temp.group_id == constants.TEMPLATE_GROUP_TEXT) {
              this.form.qna.push({
                question_id: temp.id, q_type: temp.group, answer_id: null, file_count: 0, answer_text: '', other_text: '',
              })
            } else {
              this.form.qna.push({
                question_id: temp.id, q_type: temp.group, answer_id: null, file_count: 0, from_master: temp.from_master, answer_text: { category: { id: '', products: { id: '', subproducts: [], quality: { id: '' } } } }, other_text: '',
              })
            }
          })
          this.template = response.data.data.question_data

          if (this.service_id) {
            this.getSurvey()
          }
          hideLoader()
        }
        hideLoader()
      }).catch()
    },
  },
}
</script>

<style lang="scss">
.tab-card-fixed-height{
    height: 320px;
    min-height: 320px;
    height: calc(100vh - 504px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height-noscroll {
   min-height: 320px;
    height: calc(100vh - 504px);
    overflow: hidden;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
display: none;
}
.mb-5px{
  margin-bottom: 5px;
}
</style>
