<template>
  <div>
    <div
      v-for="(parent, PIndex) in repeaterArray"
      :key="parent.id"
      :class="parent.is_inner_repeater_questions === 0 ? 'table-responsive mh-230 table-scrollbar' : ''"
    >
      <div v-if="parent.is_inner_repeater_questions === 0">
        <table
          class="table table-bordered table-hover table-striped"
        >
          <thead class="fixed-header">
            <tr>
              <th :colspan="parent.repeater[0].repeater_data.length + 1">
                {{ parent.question }}
              </th>
            </tr>
            <tr>
              <th
                v-for="(item) in parent.repeater[0].repeater_data"
                :key="item.id"
              >{{ item.question }}
              </th>
              <th>{{ t('Action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(el, index) in parent.repeater"
              :key="el.id"
            >
              <td
                v-for="(item, idx) in el.repeater_data"
                :key="item.id"
              >
                <b-form-input
                  v-if="item.group_id === 4004"
                  :id="item.id.toString()"
                  v-model="item.answer_text"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :type="item.group_name"
                />
                <v-select
                  v-if="(item.group_id === 4001 && item.options.length)"
                  v-model="item.answer_id"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select"
                  label="options"
                  :reduce="op => op.id"
                  :options="item.options"
                />
                <v-select
                  v-if="(item.group_id === 4001 && item.master_data.length)"
                  v-model="item.answer_id"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select"
                  label="options"
                  :reduce="op => op.id"
                  :options="item.master_data"
                />
                <b-form-file
                  v-if="item.group_id === 4005"
                  v-model="item.answer_media"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  type="file"
                  placeholder="Upload File"
                  @input="uploadFile(PIndex, index, idx)"
                />
                <flat-pickr
                  v-if="item.group_id == 4008"
                  v-model="item.answer_text"
                  class="form-control"
                  :config="{ dateFormat: dateformat,}"
                />
              </td>
              <td class="text-center">
                <b-button
                  v-show="index > 0"
                  type="button"
                  variant="danger"
                  @click="removeItem(PIndex, index)"
                > <i class="fas fa-minus"></i>
                </b-button>
                <!-- <b-button
                  v-show="index === el.repeater_data.length - 1"
                  class="ml-2"
                  type="button"
                  variant="success"
                  @click="addItem(PIndex)"
                > <i class="fas fa-plus"></i>
                </b-button> -->
              </td>
            </tr>
            <tr>
              <td :colspan="parent.repeater[0].repeater_data.length"></td>
              <td class="text-center">
                <b-button
                  type="button"
                  variant="primary"
                  @click="addItem(PIndex)"
                > {{ t('Add') }}
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- inner_repeater_questions -->
    <div
      v-for="(parent, PIndex) in repeaterArray"
      :key="parent.id"
      :class="parent.is_inner_repeater_questions === 1 ? 'outer-border my-2' : ''"
    >
      <div v-if="parent.is_inner_repeater_questions === 1">
        <div
          v-for="(el, index) in parent.repeater"
          :key="el.id"
          class="border-bottom"
        >
          <b-row class="p-1">
            <b-col
              v-for="(item, idx) in el.repeater_data.slice(0, -1)"
              :key="item.id"
            >
              <b-form-group :label="item.question">
                <b-form-input
                  v-if="item.group_id === 4004"
                  :id="item.id.toString()"
                  v-model="item.answer_text"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :type="item.group_name"
                />
                <v-select
                  v-if="(item.group_id === 4001 && item.options.length)"
                  v-model="item.answer_id"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select"
                  label="options"
                  :reduce="op => op.id"
                  :options="item.options"
                  @input="getMappingDropdownList($event, item.is_mapped, item.question_id, PIndex, index)"
                />
                <v-select
                  v-if="(item.group_id === 4001 && item.master_data.length)"
                  v-model="item.answer_id"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Select"
                  label="options"
                  :reduce="op => op.id"
                  :options="item.master_data"
                  @input="getMappingDropdownList($event, item.is_mapped, item.question_id, PIndex, index)"
                />
                <b-form-file
                  v-if="item.group_id === 4005"
                  v-model="item.answer_media"
                  :class="el.repeater_data.length > 3 ? 'width-300' : '' "
                  type="file"
                  placeholder="Upload File"
                  @input="uploadFile(PIndex, index, idx)"
                />
              </b-form-group>
            </b-col>
            <b-col class="mt-2">
              <b-button
                v-show="index > 0"
                class="mr-1"
                type="button"
                variant="danger"
                @click="removeOuterItem(PIndex, index)"
              >
                <i class="fas fa-minus"></i>
              </b-button>
              <b-button
                v-if="index === parent.repeater.length - 1"
                type="button"
                variant="success"
                @click="addOuterItem(PIndex, index)"
              >
                <i class="fas fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <div
            v-for="(item, Index) in el.repeater_data"
            :key="item.id"
          >
            <div v-if="item.group_id === 4007">
              <div class="table-responsive mh-230 table-scrollbar">
                <table class="table table-bordered table-hover table-striped mt-1">
                  <thead class="fixed-header">
                    <tr>
                      <th
                        v-for="(ele) in item.inner_repeater[0].repeater_data"
                        :key="ele.id"
                      >
                        {{ ele.question }}
                      </th>
                      <th>{{ t('Action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, RIndex) in item.inner_repeater"
                      :key="value.id"
                    >
                      <td
                        v-for="(inner, Idx) in value.repeater_data"
                        :key="inner.id"
                      >
                        <b-form-input
                          v-if="inner.group_id === 4004"
                          :id="inner.id.toString()"
                          v-model="inner.answer_text"
                          :class="value.repeater_data.length > 2 ? 'width-400' : '' "
                          :type="inner.group_name"
                        />
                        <v-select
                          v-if="(inner.group_id === 4001 && inner.options.length)"
                          v-model="inner.answer_id"
                          :class="value.repeater_data.length > 2 ? 'width-300' : '' "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="options"
                          :reduce="op => op.id"
                          :options="inner.options"
                        />
                        <v-select
                          v-if="(inner.group_id === 4001 && inner.master_data.length)"
                          v-model="inner.answer_id"
                          :class="value.repeater_data.length > 2 ? 'width-300' : '' "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="options"
                          :reduce="op => op.id"
                          :options="inner.master_data"
                        />
                        <b-form-file
                          v-if="inner.group_id === 4005"
                          v-model="inner.answer_media"
                          :class="value.repeater_data.length > 2 ? 'width-300' : '' "
                          type="file"
                          placeholder="Upload File"
                          @input="uploadFile(PIndex, Index, Idx)"
                        />
                      </td>
                      <td class="d-flex">
                        <b-button
                          v-if="RIndex > 0"
                          class="mr-1"
                          type="button"
                          variant="danger"
                          @click="removeInnerItem(PIndex, index, Index, RIndex)"
                        >
                          <i class="fas fa-minus"></i>
                        </b-button>
                        <b-button
                          v-if="RIndex === item.inner_repeater.length - 1"
                          type="button"
                          variant="success"
                          @click="addInnerItem(PIndex, index, Index)"
                        >
                          <i class="fas fa-plus"></i>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  failedToast, hideLoader, showLoader,
} from '@/utils/common'

export default {
  components: {
    vSelect,
  },
  props: {
    repeaterArray: { type: Array, required: false, default: () => [] },
    ducumentFile: { type: Array, required: false, default: () => [] },
    dateformat: { type: String, required: false, default: 'Y-m-d' },
  },
  data() {
    return {
      repeater_data: [],
      mappingDropdown: [],
      emptyArray: [
        { id: null, options: 'No Data' },
      ],
    }
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  watch: {
    repeaterArray: {
      handler() {
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    addItem(index) {
      this.repeater_data = []
      this.repeaterArray[index].repeater[this.repeaterArray[index].repeater.length - 1].repeater_data.forEach(element => {
        this.repeater_data.push(
          {
            id: element.id + 1,
            template_id: element.template_id,
            opportunity_id: element.opportunity_id,
            company_id: element.company_id,
            parent_question_id: element.parent_question_id,
            question_id: element.question_id,
            answer_id: '',
            answer_text: '',
            other_text: '',
            media_id: '',
            question: element.question,
            group_id: element.group_id,
            group_name: element.group_name,
            from_master: element.from_master,
            master_table: element.master_table,
            master_data: element.master_data,
            is_multiple: element.is_multiple,
            options: element.options,
            answer_media: '',
            file_count: 0,
            raw_id: element.raw_id + 1,
          },
        )
      })
      this.repeaterArray[index].repeater.push(
        {
          id: this.repeaterArray[index].repeater.length + 1,
          repeater_data: this.repeater_data,
        },
      )
    },
    removeItem(PIndex, index) {
      if (index > 0) {
        this.repeaterArray[PIndex].repeater.splice(index, 1)
      }
    },
    addOuterItem(PIndex, index) {
      const RepeaterData = this.repeaterArray[PIndex].repeater[index].repeater_data
      const innerRepeaterData = RepeaterData.find(value => value.group_id === 4007)

      const innerRepeaterQuestion = innerRepeaterData.inner_repeater[0].repeater_data.map(ele => ({
        id: ele.id + 1,
        answer_id: null,
        answer_text: '',
        company_id: ele.company_id,
        from_master: ele.from_master,
        group_id: ele.group_id,
        group_name: ele.group_name,
        is_mapping: ele.is_mapping,
        is_multiple: ele.is_multiple,
        is_other_text: ele.is_other_text,
        is_required: ele.is_required,
        mapping_question_id: ele.mapping_question_id,
        master_data: ele.master_data,
        master_table: ele.master_table,
        media_id: ele.media_id,
        num_of_options: ele.num_of_options,
        options: ele.options,
        other_text: ele.other_text,
        parent_question_id: ele.parent_question_id,
        question: ele.question,
        question_id: ele.question_id,
        raw_id: ele.raw_id + 1,
        template_id: ele.template_id,
        type_id: ele.type_id,
        inner_repeater: [],
      }))

      const Obj = {
        id: innerRepeaterData.inner_repeater[0].id + 1,
        repeater_data: innerRepeaterQuestion,
      }

      const newRepeaterData = RepeaterData.map(element => ({
        id: element.id + 1,
        answer_id: null,
        answer_text: '',
        company_id: element.company_id,
        from_master: element.from_master,
        group_id: element.group_id,
        group_name: element.group_name,
        is_mapped: element.is_mapped,
        is_mapping: element.is_mapping,
        is_multiple: element.is_multiple,
        is_other_text: element.is_other_text,
        is_required: element.is_required,
        mapping_question_id: element.mapping_question_id,
        master_data: element.master_data,
        master_table: element.master_table,
        media_id: element.media_id,
        num_of_options: element.num_of_options,
        options: element.options,
        other_text: element.other_text,
        parent_question_id: element.parent_question_id,
        question: element.question,
        question_id: element.question_id,
        raw_id: element.raw_id + 1,
        template_id: element.template_id,
        type_id: element.type_id,
        inner_repeater: element.group_id === 4007 ? [Obj] : [],
      }))
      this.repeaterArray[PIndex].repeater.push({
        id: this.repeaterArray[PIndex].repeater[index].id + 1,
        repeater_data: newRepeaterData,
      })
      // console.log('this.repeaterArray', this.repeaterArray)
    },
    addInnerItem(PIndex, IIndex, Index) {
      const array1 = this.repeaterArray[PIndex].repeater[IIndex].repeater_data[Index].inner_repeater
      const innerRepeaterData = array1[array1.length - 1].repeater_data
      const newInnerRepeaterData = innerRepeaterData.map(element => ({
        id: element.id + 1,
        answer_id: null,
        answer_text: '',
        company_id: element.company_id,
        group_id: element.group_id,
        group_name: element.group_name,
        is_inner_repeater_questions: element.is_inner_repeater_questions,
        from_master: element.from_master,
        master_table: element.master_table,
        master_data: element.master_data,
        is_mapped: element.is_mapped,
        is_mapping: element.is_mapping,
        is_multiple: element.is_multiple,
        is_other_text: '',
        is_required: element.is_required,
        mapping_question_id: element.mapping_question_id,
        options: element.options,
        question_id: element.question_id,
        num_of_options: element.num_of_options,
        parent_question_id: element.parent_question_id,
        question: element.question,
        raw_id: element.raw_id + 1,
        template_id: element.template_id,
        type_id: element.type_id,
        media_id: '',
        file_count: 0,
      }))
      this.repeaterArray[PIndex].repeater[IIndex].repeater_data[Index].inner_repeater.push({
        id: array1[array1.length - 1].id + 1,
        repeater_data: newInnerRepeaterData,
      })
    },
    removeOuterItem(PIndex, index) {
      if (index > 0) {
        this.repeaterArray[PIndex].repeater.splice(index, 1)
      }
    },
    removeInnerItem(PIndex, IIndex, Index, RIndex) {
      if (RIndex > 0) {
        this.repeaterArray[PIndex].repeater[IIndex].repeater_data[Index].inner_repeater.splice(RIndex, 1)
      }
    },
    uploadFile(PIndex, index, idx) {
      this.ducumentFile.push(
        {
          id: this.repeaterArray[PIndex].repeater[index].id,
          question_id: this.repeaterArray[PIndex].repeater[index].repeater_data[idx].question_id,
          answer_media: this.repeaterArray[PIndex].repeater[index].repeater_data[idx].answer_media,
        },
      )
    },
    async getMappingDropdownList(event, isMapped, questionId, PIndex, index) {
      if (isMapped === 1) {
        showLoader()
        await store.dispatch('questionsStore/getMappingDropdown',
          {
            id: event,
            question_id: questionId,
          }).then(response => {
          if (response.data.data.data.length) {
            this.mappingDropdown = response.data.data.data
          } else {
            this.mappingDropdown = this.emptyArray
          }
          const questionID = response.data.data.question_id
          // console.log('questionID', questionID);

          this.repeaterArray[PIndex].repeater[index].repeater_data.forEach(element => {
            if (element.group_id === 4007) {
              element.inner_repeater.forEach(el => {
                if (el.repeater_data.length) {
                  const updatedEl = {
                    ...el,
                    repeater_data: el.repeater_data.map(p => {
                      if (p.mapping_question_id === questionID) {
                        return {
                          ...p,
                          master_data: p.master_data.length ? this.mappingDropdown : this.emptyArray,
                          // options: p.options.length ? this.mappingDropdown : this.emptyArray,
                        }
                      }
                      return { ...p }
                    }),
                  }
                  const INDEX = element.inner_repeater.indexOf(el)
                  if (INDEX > -1) {
                    element.inner_repeater.splice(INDEX, 1, updatedEl)
                  }
                }
              })
            }
          })
          hideLoader()
        }).catch(e => {
          failedToast(e.msg)
          hideLoader()
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-scrollbar {
  min-height: 200px;
}
::-webkit-scrollbar {
  width: 6px;
  max-height: 9px;
  height: 9px !important;
}
.width-300 {
  min-width: 300px;
}
.width-400 {
  min-width: 400px;
}
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 100;
}
.outer-border {
  border: 1px solid #ebe9f1;
  border-radius: 4px;
}
</style>
